import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { PageLoader } from "../components";
import { Wrapper } from "../container/Wrapper";

import { AuthRoute } from "./AuthRoute";
import { PrivateRoute } from "./PrivateRoute";

const TestStart = React.lazy(() => import("../pages/Test/TestStart"));
const TestAttempt = React.lazy(() => import("../pages/Test/TestAttempt"));
const TestResult = React.lazy(() => import("../pages/Test/TestResult"));
const TestSolution = React.lazy(() => import("../pages/Test/TestSolution"));

// Student Pages
const Login = React.lazy(() => import("../pages/Login"));
const Register = React.lazy(() => import("../pages/Register"));
const StudentDashboard = React.lazy(() => import("../pages/StudentDashboard"));
const StudentProfile = React.lazy(() => import("../pages/StudentProfile"));
const StudentPayments = React.lazy(() => import("../pages/StudentPayments"));

const StudentForgotPassword = React.lazy(() => import("../pages/StudentForgotPassword"));
const StudentChangePassword = React.lazy(() => import("../pages/StudentChangePassword"));
const VerifyEmail = React.lazy(() => import("../pages/VerifyEmail"));

// Request Module
const RequestList = React.lazy(() => import("../pages/Request/RequestList"));
const RequestSingle = React.lazy(() => import("../pages/Request/RequestSingle"));

// Announcement
const AnnouncementList = React.lazy(() => import("../pages/Announce/AnnouncementList"));

const CourseList = React.lazy(() => import("../pages/Batch/CourseList"));
const BatchList = React.lazy(() => import("../pages/Batch/BatchList"));
const BatchSingle = React.lazy(() => import("../pages/Batch/BatchSingle"));
const BatchPerformanceDetails = React.lazy(() => import("../pages/Batch/components/BatchPerformanceDetails"));

const NotFound = React.lazy(() => import("../pages/NotFound"));

function AppRouter() {
  return (
    <BrowserRouter>
      <Wrapper>
        <Suspense fallback={<PageLoader />}>
          <Routes>
            <Route element={<AuthRoute />}>
              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />
              <Route path="forgot-password" element={<StudentForgotPassword />} />
              <Route path="forgot-password/:token" element={<StudentChangePassword />} />
              <Route path="verify-email" element={<VerifyEmail />} />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route index element={<StudentDashboard />} />
              <Route path="profile" element={<StudentProfile />} />
              <Route path="payments" element={<StudentPayments />} />

              <Route path="courses" element={<CourseList />} />

              <Route path="batches">
                <Route index element={<BatchList />} />
                <Route path=":id" element={<BatchSingle />} />
                <Route path=":id/:tab" element={<BatchSingle />} />
                <Route path=":id/:tab/:performance" element={<BatchPerformanceDetails />} />
              </Route>

              <Route path="requests">
                <Route index element={<RequestList />} />
                <Route path=":id" element={<RequestSingle />} />
              </Route>

              <Route path="announcements">
                <Route index element={<AnnouncementList />} />
              </Route>

              <Route path="test">
                <Route path=":batchId/:testId" element={<TestAttempt />} />
                <Route path=":batchId/:testId/:studentTest" element={<TestStart />} />
                <Route path=":batchId/:testId/:studentTest/result" element={<TestResult />} />
                <Route path=":batchId/:testId/:studentTest/solution" element={<TestSolution />} />
              </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </Wrapper>
    </BrowserRouter>
  );
}

export default AppRouter;
