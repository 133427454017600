import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { API_ENDPOINT } from "../../config";
import { AuthState, LoginRequest, RegisterUserRequest, Student } from "../../interfaces";
import { toastMessage } from "../../utils/toastError";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT + "/student",
    credentials: "include",
  }),
  endpoints: (build) => ({
    login: build.mutation<AuthState, LoginRequest>({
      query: (body) => ({
        url: "/login",
        method: "POST",
        body,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
    }),
    register: build.mutation<AuthState, RegisterUserRequest>({
      query: (body) => ({
        url: "/register",
        method: "POST",
        body,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
        return baseQueryReturnValue.data;
      },
      transformResponse(baseQueryReturnValue: any) {
        toastMessage("success", baseQueryReturnValue, { duration: 5000 });
        return Promise.resolve(baseQueryReturnValue);
      },
    }),
    logout: build.mutation<any, void>({
      query: () => ({
        url: "/logout",
        method: "GET",
      }),
      onQueryStarted() {
        toastMessage("success", "Succesfully Logged Out");
      },
    }),
    authenticate: build.query<any, void>({
      query: () => ({
        url: "/authenticate",
        method: "GET",
      }),
    }),
    verifyEmail: build.mutation<{ success: boolean; message: string }, { otp: number; email: string }>({
      query: (body) => ({
        url: "/verify-email",
        method: "POST",
        body,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
        return baseQueryReturnValue.data;
      },
      transformResponse(baseQueryReturnValue: any) {
        toastMessage("success", baseQueryReturnValue);
        return Promise.resolve(baseQueryReturnValue);
      },
    }),
    updateProfile: build.mutation<Student, Partial<any>>({
      query: (body) => ({
        url: "/profile",
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
  useAuthenticateQuery,
  useLazyAuthenticateQuery,
  useUpdateProfileMutation,
  useVerifyEmailMutation,
} = authApi;
